<template>
  <div class="input-group" :class="{ errorClass: errorTxt || numErr}">
    <label :for="inputId"> {{ labelTxt }} </label>
    <input :id="inputId" :type="type == 'email' ? 'email' : 'text'" :name="inputName"
           :placeholder="inputPlaceHolder" :disabled="isDisabled"
           :value="value" required
           :maxlength="maxlength"
           @input="updateValue($event)" />

    <span class="icon">!</span>
    <p v-if="errorTxt"> {{ errorTxt }}</p>
    <p v-if="numErr && !errorTxt"> {{ numErrTxt }}</p>
  </div>
</template>

<script>
export default {
  name: "InputGroup",
  data() {
    return {
      val: 0,
      numErr: false,
      numErrTxt: ""
    }
  },
  props: {
    labelTxt: {
      type: String,
      default: "Наименование"
    },
    type: {
      default: "text"
    },
    isDisabled: {
      default: false
    },
    inputPlaceHolder: {
      type: [String, Number],
      default: ""
    },
    inputName: {
      type: String,
      default: "name"
    },
    value: {},
    maxlength: {
      default: 200
    },
    minLength: {
      default: 1
    },
    inputId: {
      type: String,
      default: "01"
    },
    error: {
      type: Boolean,
      default: false
    },
    errorTxt: {
      type: String,
    },
    isRequired: {
      default: false
    }
  },

  methods: {
    updateValue: function (e) {
      if(this.type == "number") {
        this.val = e.target.value.replace(/[^\d]/g,'')
        e.target.value = this.val;
      }


      if(e.target.value.length < this.minLength)
      {
        this.numErr = true;
        this.numErrTxt = "Минимальное количество символов: " + this.minLength;
      }
      else {
        this.numErr = false;
        this.numErrTxt = "";
      }

      this.$emit('input', e.target.value);


    }
  }
}
</script>

<style scoped lang="scss">

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  label {
    color: $navy;
    margin-bottom: 11px;
  }
  input {
    font-size: 18px;
    color: $text-gray;
    padding: 18px 23px;
    border: 1px solid $light-border;
    border-radius: 8px;
  }
  .icon {
    display: none;
  }

  input:focus {
    outline: none;
    color: #000;
  }

  p {
    color: #e5221c;
    margin-top: 10px;
    font-size: 16px;
    line-height: 26px;
  }
}

.input-group.errorClass {
  input {
    background-color: #fce8e8;
    border-color: #e5221c;
  }
}
@include md-max {


  //.input-group.errorClass input:before {
  //  content: '!';
  //  display: block;
  //  background-color: #e5221c;
  //  border-radius: 20px;
  //  text-align: center;
  //  color: #fff;
  //  height: 24px;
  //  width: 24px;
  //  right: 20px;
  //}


  .input-group.errorClass {
    .icon {
      display: block;
      position: absolute;
      background-color: #e5221c;
      border-radius: 20px;
      text-align: center;
      color: #fff;
      height: 24px;
      width: 24px;
      right: 20px;
      margin-top: 45px;
    }
  }


  .input-group {
    label {
      margin-bottom: 8px;
    }

    input {
      font-size: 16px;
      line-height: 20px;
      padding: 14px 21px;
    }

    p {
      line-height: 19px;
    }
  }
}
</style>
